<script lang="ts" setup>
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent, type Component } from 'vue'

const components: Record<string, Component> = {
  FreedomTheme: defineAsyncComponent(async () => await import('./FreedomTheme.vue')),
  CentumTheme: defineAsyncComponent(async () => await import('./CentumTheme.vue')),
}

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      isSweden: getServerState('isSweden'),
      isNorway: getServerState('isNorway'),
      isFinland: getServerState('isFinland'),
    }

const theme = (): string | undefined => {
  if (ctx?.isSweden) return 'FreedomTheme'
  else if (ctx?.isNorway || ctx?.isFinland) return 'CentumTheme'
}

const currentTheme = theme()
</script>

<template>
  <component :is="components[currentTheme]" v-if="currentTheme" :key="currentTheme" />
</template>
